




















import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { JobSearchResult as JobSearchResultDataType } from '@/store/search/types'
import SearchResultCard from '@/components/search-results/SearchResultCard.vue'
@Component({
  components: { SearchResultCard }
})
// currently not used - needs to be checked
// @group NOT USED
export default class JobSearchResult extends Vue {
  @Prop()
  props: JobSearchResultDataType

  info: string[] = []

  type = ''
  jobTitle = ''
  occupationalTitle = ''
  workingHours = ''
  city = ''
  startDate: Date | null = null
  endDate: Date | null = null
  company = ''

  @Watch('props')
  onPropsChanged (newValue: JobSearchResultDataType): void {
    this.type = newValue.type
    this.jobTitle = newValue.title
    this.workingHours = newValue.workingHours
    this.occupationalTitle = newValue.occupationalTitle
    this.city = newValue.city
    this.startDate = newValue.startDate
    this.endDate = newValue.endDate
    this.company = newValue.company
  }

  mounted (): void {
    this.type = this.props.type
    this.jobTitle = this.props.title
    this.workingHours = this.props.workingHours
    this.occupationalTitle = this.props.occupationalTitle
    this.city = this.props.city
    this.startDate = this.props.startDate
    this.endDate = this.props.endDate
    this.company = this.props.company
  }
}
