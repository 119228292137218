




































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

// currently only used in not used components - needs to be checked
// @group NOT USED
@Component
export default class SearchResultCard extends Vue {
  @Prop()
  cardText: string[]

  @Prop()
  title: string

  @Prop()
  subTitle: string

  @Prop()
  image: string
}
