









































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import ResultsPage from '@/components/search-results/ResultsPage.vue'
import { JobFinderType } from '@/store/kode/types'
import JobFamily from '@/components/hszg/JobFamily.vue'
@Component({
  components: { ResultsPage, JobFamily }
})
// This is displaying the results from the job finder and the matching jobs to a talents job family.
// The results are shown with a green bar which represents the accuracy of fit.
// Internally it uses the results page component in combination with the job family component
// @group SEARCH RESULTS
export default class MatchingResultOverview extends Vue {
  // unique identifier
  @Prop({ required: true })
  id: string

  // h1 title for the results page
  @Prop()
  title: string

  // results type to display how many results are found (e.g. 9 job families)
  @Prop()
  resultType: string

  // content array for the results page (check type)
  @Prop()
  items: JobFinderType[]

  // Which result type should be displayed (used differently on different pages). Valid types:
  // `'JOB_FAMILY'`, `'CROSS_COMPETENCES'`, `'JOB_AD'`, `'JOB_AD_NO_COMP'`
  @Prop({
    required: true,
    validator (value: string): boolean {
      return ['JOB_FAMILY', 'CROSS_COMPETENCES', 'JOB_AD', 'JOB_AD_NO_COMP'].indexOf(value) !== -1
    }
  })
  matchingResultType: string

  @Watch('matchingResultType', { immediate: true })
  onMatchingResultTypeChanged (newValues: string): void {
    this.calculationBasis = newValues === 'JOB_AD_NO_COMP' ? 'qualification' : 'average'
  }

  @Watch('items', { immediate: true })
  onModelChanged (newValues: JobFinderType[]): void {
    if (JSON.stringify(newValues) !== JSON.stringify(this.itemsState)) {
      this.itemsState = this.sort(newValues)
    }
  }

  itemsState: JobFinderType[] = []
  calculationBasis = 'average'

  get calculationBasisOptions (): { text: string; value: string }[] {
    switch (this.matchingResultType) {
      case 'JOB_FAMILY': return [
        { text: this.$i18n.t('job-finder.average').toString(), value: 'average' },
        { text: this.$i18n.t('job-finder.favorable').toString(), value: 'favorable' },
        { text: this.$i18n.t('job-finder.unfavorable').toString(), value: 'unfavorable' }
      ]
      case 'CROSS_COMPETENCES': return [
        { text: this.$i18n.t('job-finder.average').toString(), value: 'average' },
        { text: this.$i18n.t('job-finder.favorable').toString(), value: 'favorable' },
        { text: this.$i18n.t('job-finder.unfavorable').toString(), value: 'unfavorable' }
      ]
      case 'JOB_AD': return [
        { text: this.$i18n.t('jobs-to-job-family.average-matching').toString(), value: 'average' },
        { text: this.$i18n.t('jobs-to-job-family.competence-matching').toString(), value: 'competence' },
        { text: this.$i18n.t('jobs-to-job-family.qualification-matching').toString(), value: 'qualification' }
      ]
      case 'JOB_AD_NO_COMP': return [
        { text: this.$i18n.t('jobs-to-job-family.qualification-matching').toString(), value: 'qualification' }
      ]
      default: return [
        { text: '', value: '' }
      ]
    }
  }

  sortResultJobFamilies (event: any): void {
    this.calculationBasis = event
    this.itemsState = this.sort(this.itemsState)
  }

  sort (itemsToSort: JobFinderType[]): Array<JobFinderType> {
    let aScore = 0
    let bScore = 0
    return itemsToSort.sort((a: JobFinderType, b: JobFinderType) => {
      switch (this.calculationBasis) {
        case 'average': {
          aScore = a.average ? a.average : 0
          bScore = b.average ? b.average : 0
          break
        }
        case 'favorable': {
          aScore = a.favorable ? a.favorable : 0
          bScore = b.favorable ? b.favorable : 0
          break
        }
        case 'unfavorable': {
          aScore = a.unfavorable ? a.unfavorable : 0
          bScore = b.unfavorable ? b.unfavorable : 0
          break
        }
        case 'competence': {
          aScore = a.competenceMatchingResult ? a.competenceMatchingResult : 0
          bScore = b.competenceMatchingResult ? b.competenceMatchingResult : 0
          break
        }
        case 'qualification': {
          aScore = a.qualificationMatchingResult ? a.qualificationMatchingResult : 0
          bScore = b.qualificationMatchingResult ? b.qualificationMatchingResult : 0
        }
      }
      return aScore > bScore ? -1 : 1
    })
  }
}
